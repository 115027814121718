.markdown-style p:not(:last-child) {
  margin-bottom: 10px;
  width: auto;
}

.markdown-style p {
  max-width: 600px;
}

.markdown-style code {
  max-width: 600px;
}

.markdown-style pre {
  max-width: 600px;
}

.markdown-style li {
  max-width: 600px;
}

.small-style p:not(:last-child) {
  margin-bottom: 10px;
  max-width: 300px;
}

.small-style p {
  max-width: 300px;
}

.small-style code {
  max-width: 300px;
}

.small-style pre {
  max-width: 300px;
}

.small-style li {
  max-width: 300px;
}
