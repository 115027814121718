/* ./src/index.css */
@import url("https://fonts.googleapis.com/css2?family=Red+Hat+Display:wght@400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Permanent+Marker&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  @apply text-text;
}

button:focus,
input:focus {
  @apply outline-none;
}
